import Vue from 'vue'
import {mapGetters} from 'vuex'
import ApiClientsService from 'js/services/api/api-clients.js'
import ClientsService from 'js/services/api/clients.js'
import QrTokensService from 'js/services/api/qr-tokens.js'

import template from './index.pug'

const CC_DOCUMENT_TYPE = 3
const CC_REGEX = /^\d{6,9}$/

Vue.component('everilion-register-form', {
  template: template(),
  data() {
    const vm = this

    return {
      web_title: Vue.store.state.webTitle,
      // Control del formulario
      step: 'rut_passport',
      documentType: undefined,
      rutPassport: '',
      rutPassportError: undefined,
      // Formulario 2A - Registro en Everilion
      registerForm: {
        Email: '',
        DocumentType: 1,
        RUTPassport: '',
        MunicipalityId: undefined,
        Name: '',
        SurName1: '',
        SurName2: '',
        Birthday: '',
        Address: '',
        PostalCode: '',
        Phone: '',
        SupportId: undefined,
        Accept: false
      },
      fieldsErrorsRegister: vm.resetFieldErrors(),
      errorRegister: undefined,
      datepickerConfig: {
        allowInput: true,
        formatDate(date) { return moment(date).format('DD/MM/YYYY') },
        parseDate(date) { return moment(date, 'DD/MM/YYYY').toDate() },
        disableMobile: true
      },
      conditions: false,
      // Formulario 2B - Código de TSC
      tscCode: '',
      errorsValidationTSC: undefined,
      onlyNumbers: (oldValue, value) => {
        if (value === '') return ''
        if (value && String(value).trim().match(/^[0-9]*$/)) {
          return String(value).trim()
        }
        if (oldValue) {
          return String(oldValue).trim()
        }
        return ''
      }
    }
  },
  computed: {
    ...mapGetters(['administrativeArea']),
    hasCompensationCenter() {
      return this.administrativeArea && this.administrativeArea.compensation_center_id
    },
    documentTypeOptions() {
      return this.$currentAppConfig.document_types.map(documentType => (
        {
          label: this.$t(`everilionRegisterForm.rutPassport.documentType.${documentType}`),
          value: documentType
        }
      ))
    }
  },
  watch: {
    $currentAppConfig() {
      this.setDocumentType()
    }
  },
  created() {
    this.setDocumentType()
  },
  methods: {
    setDocumentType() {
      this.documentType = this.$currentAppConfig.default_document_type
    },
    subbmitRutPassport() {
      this.rutPassportError = ''

      const errorTranslationBase = 'everilionRegisterForm.rutPassport.errors'

      // Eliminar posibles espacios en blanco
      if (this.rutPassport) {
        this.rutPassport = $.trim(this.rutPassport)
      }

      // Control de errores
      if (this.rutPassport === '') {
        this.rutPassportError = this.$t(`${errorTranslationBase}.emptyIdentifier`)
      } else if (this.documentType === CC_DOCUMENT_TYPE && !this.rutPassport.match(CC_REGEX)) {
        this.rutPassportError = this.$t(`${errorTranslationBase}.invalidCC`)
      }

      if (this.rutPassportError) return

      const params = {
        administrative_area_id: this.administrativeArea.id,
        rut: this.rutPassport,
        document_type: this.documentType
      }

      ApiClientsService.existsAccount(params).then(() => {
        ApiClientsService.getClient(params).then(response => {
          // El cliente existe, se pasa a comprobar si tiene TSC activas
          // Si las tiene debe validar el número para que se le activen
          if (response.tsc) {
            this.step = 'tsc_form'
          } else {
            this.checkUser()
          }
        // Error de Everilion o no cliente
        }, response => {
          if (response.data == null) {
            this.rutPassportError = this.$t(`${errorTranslationBase}.retry`)
          // El cliente no existe, se va al paso 2 del formulario
          } else {
            this.getQrToken()
          }
        })
      }, response => {
        if (response.data && response.data.data && response.data.data.rut === 'taken') {
          this.rutPassportError = this.$t(`${errorTranslationBase}.identifierTaken`)
        }
      })
    },

    validateRegisterForm() {
      const vm = this

      let valid = true

      // No se han aceptado los términos de uso
      if (!vm.registerForm.Accept) {
        vm.fieldsErrorsRegister.Accept = vm.$t(
          'everilionRegisterForm.registerEverilion.errors.accept'
        )
        valid = false
      // Se han aceptado los términos de uso
      } else {
        const fieldsFilledByUser = [
          'Name', 'SurName1', 'Birthday', 'PostalCode', 'Phone'
        ]

        _.each(fieldsFilledByUser, field => {
          const typedValue = vm.registerForm[field]

          if (!typedValue || !$.trim(typedValue)) {
            vm.fieldsErrorsRegister[field] = vm.$t(
              'everilionRegisterForm.registerEverilion.errors.invalidField'
            )
            valid = false
          }
        })
      }

      return valid
    },

    registerEverilion() {
      const vm = this

      vm.errorRegister = undefined
      vm.fieldsErrorsRegister = vm.resetFieldErrors()

      if (vm.validateRegisterForm()) {
        const params = {
          administrative_area_id: vm.administrativeArea.id,
          client: JSON.parse(JSON.stringify(vm.registerForm))
        }

        // Convertimos la fecha al formato de Everilion
        params.client.Birthday = `/Date(${moment.utc(
          moment(vm.registerForm.Birthday).format('YYYY-MM-DD')
        ).valueOf()}+0000)/`

        ApiClientsService.registerClient(params).then(() => {
          vm.$notifications.send({
            id: 'everilion-register-form-success',
            text: vm.$t('everilionRegisterForm.registerEverilion.success'),
            type: 'success',
            close: true
          })
          vm.$emit('reload-view')
        }, response => {
          if (response.data == null) {
            vm.errorRegister = vm.$t('everilionRegisterForm.rutPassport.errors.retry')
          } else if (response.data.data && response.data.data.rut) {
            vm.errorRegister = vm.$t('everilionRegisterForm.rutPassport.errors.rutTaken')
          } else if (_.includes([-1, -5, -10, -23, -24, -25, -26, -27, -28], response.data.data)) {
            vm.errorRegister = vm.$t(
              `everilionRegisterForm.registerEverilion.errors.errorSubmit${response.data.data * -1}`
            )
          } else {
            vm.errorRegister = vm.$t('everilionRegisterForm.registerEverilion.errors.errorSubmit1')
          }
        })
      }
    },

    checkUser() {
      const vm = this

      ClientsService.get(vm.$user.id).then(response => {
        const clientAccount = response.client_accounts.find(
          account => account.administrative_area_id === vm.administrativeArea.id
        )

        // Si el usuario no tiene cuenta en el municipio se le manda a que indique su rut
        if (!clientAccount) {
          vm.step = 'rut_passport'
        // Si el usuario tiene cuenta se le recarga la vista
        } else {
          vm.$emit('reload-view')
        }
      })
    },

    /*
     * Obtiene un token QR nuevo del bakend y procede al fomulario de registro de Everilion
     */
    getQrToken() {
      const vm = this
      QrTokensService.get().then(response => {
        vm.registerForm.SupportId = response.token
        vm.registerForm.DocumentType = vm.documentType
        vm.registerForm.RUTPassport = vm.rutPassport
        vm.registerForm.Email = vm.$user.email
        vm.registerForm.MunicipalityId = vm.administrativeArea.code
        vm.step = 'register_form'
      })
    },

    validateTSCCode() {
      const vm = this
      vm.errorsValidationTSC = undefined

      const params = {
        administrative_area_id: vm.administrativeArea.id,
        rut: vm.rutPassport,
        tsc: vm.tscCode,
        document_type: vm.documentType
      }

      ApiClientsService.validateClientTSC(params).then(() => {
        vm.$notifications.send({
          id: 'everilion-validate-tsc-success',
          text: vm.$t('everilionRegisterForm.validateTsc.success'),
          type: 'success',
          close: true
        })
        vm.$emit('reload-view')
      }, () => {
        vm.errorsValidationTSC = vm.$t('everilionRegisterForm.validateTsc.error',
          {web_title: this.web_title})
      })
    },

    showConditions() {
      this.conditions = true
    },

    closeConditions() {
      this.conditions = false
    },

    resetFieldErrors() {
      return {
        Name: undefined,
        SurName1: undefined,
        SurName2: undefined,
        Birthday: undefined,
        PostalCode: undefined,
        Address: undefined,
        Phone: undefined,
        Accept: undefined
      }
    }
  }
})
