import Vuex from 'vuex'

import location from 'js/vue-plugins/vuex/modules/location'
import municipality from 'js/vue-plugins/vuex/modules/municipality'
import administrativeArea from 'js/vue-plugins/vuex/modules/administrative_area'
import map from 'js/vue-plugins/vuex/modules/map'
import country from 'js/vue-plugins/vuex/modules/country'
import languageModal from 'js/vue-plugins/vuex/modules/language_modal'
import appConfig from 'js/vue-plugins/vuex/modules/app-config'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    location,
    municipality,
    administrativeArea,
    map,
    country,
    languageModal,
    appConfig
  },

  state: {
    webTitle: WEB_TITLE,
    appCode: APP_CODE,
    languages: LANGUAGES,
    language: localStorage.getItem('app-language') || LANGUAGES[0],
    fallbackLocale: FALLBACK_LANGUAGE || 'es',
    currentUser: null,
    device: 'desktop',
    selectedRouteId: undefined,
    selectedSublineId: undefined,
    loading: 0,
    notifications: []
  },

  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user
    },

    incrementLoading(state) {
      state.loading += 1
    },

    decreaseLoading(state) {
      state.loading -= 1
    },

    setLanguage(state, language) {
      state.language = language
    },

    ADD_NOTIFICATION(state, notification) {
      state.notifications.push(notification)
    },

    UPDATE_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications
    },

    setSelectedRouteId(state, routeId) {
      state.selectedRouteId = routeId
    },

    setSelectedSublineId(state, sublineId) {
      state.selectedSublineId = sublineId
    },

    setDevice(state, device) {
      state.device = device
    }
  },

  actions: {
    loadCurrentUser() {
      return Vue.http.get('user').then(response => {
        const user = response.data.data
        if (user.role_name === 'Client') {
          Vue.auth.setUser(user)
          return true
        }
        Vue.auth.logout()
        return false
      }, error => {
        // En caso de 401, se quita la sesión
        if (error.state === 401) {
          Vue.auth.setUser(undefined)
        }
        return false
      })
    }
  },

  getters: {
    currentUser(state) {
      return state.currentUser
    },

    loading(state) {
      return state.loading
    },

    clientAccounts(state) {
      if (state.currentUser) {
        return state.currentUser.client_accounts
      }
      return []
    },

    currentClientAccount(state, getters) {
      const {administrativeArea: aa, clientAccounts} = getters

      if (clientAccounts.length && aa) {
        return clientAccounts.find(clientAccount => clientAccount.administrative_area_id === aa.id)
      }

      return null
    },

    device(state) {
      return state.device
    },

    isPortable(state) {
      return (state.device === 'tablet' || state.device === 'mobile')
    }
  }
})

Vue.store = store

export default store
