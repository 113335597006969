import Vue from 'vue'
import template from './_login-form.pug'

// @vue/component
export default Vue.extend({
  template: template(),
  props: {
    step: {
      type: String,
      default: 'login'
    }
  },
  data() {
    return {
      web_title: Vue.store.state.webTitle,
      steps: ['login', 'resetPassword', 'resetPasswordFinish', 'register', 'registerFinish'],
      localStep: 'login',
      loginEmail: '',
      loginPassword: '',
      loginError: undefined,
      resetPasswordEmail: '',
      resetPasswordError: undefined,
      registerEmail: '',
      registerPassword: '',
      registerPasswordConfirmation: '',
      registerAcceptConditions: false,
      registerAcceptConditionsError: undefined,
      registerErrors: {
        email: undefined,
        password: undefined,
        password_confirmation: undefined
      }
    }
  },
  computed: {
    loginUserErrorClass() {
      const vm = this

      if (vm.loginError) {
        return 'error'
      }
      return undefined
    },
    getLoginPanelTitle() {
      return this.$t(`loginForm.${this.localStep}.title`, {web_title: this.web_title})
    }
  },
  watch: {
    step(newStep) {
      if (this.localStep !== newStep) {
        this.localStep = newStep
      }
    },
    localStep(newStep) {
      this.$emit('change-step', newStep)
      this.loginError = undefined
      this.resetPasswordEmail = ''
      this.resetPasswordError = undefined
    }
  },
  created() {
    this.localStep = this.step
  },
  methods: {
    login() {
      const vm = this

      if (vm.loginEmail) {
        vm.loginEmail = $.trim(vm.loginEmail)
      }

      vm.loginError = undefined

      vm.$auth.login({email: vm.loginEmail, password: vm.loginPassword}).then(
        vm.handleLoginSuccess,
        vm.handleLoginError
      )
    },

    register() {
      const vm = this

      if (vm.registerEmail) {
        vm.registerEmail = $.trim(vm.registerEmail)
        vm.registerPassword = $.trim(vm.registerPassword)
        vm.registerPasswordConfirmation = $.trim(vm.registerPasswordConfirmation)
      }

      vm.registerErrors = {
        email: undefined,
        password: undefined,
        password_confirmation: undefined
      }
      vm.registerAcceptConditionsError = undefined

      if (!vm.registerAcceptConditions) {
        vm.registerAcceptConditionsError = vm.$t('loginForm.register.conditionsError')
      } else {
        vm.$auth.registration({
          email: vm.registerEmail,
          password: vm.registerPassword,
          password_confirmation: vm.registerPasswordConfirmation
        }).then(
          vm.handleRegisterSuccess,
          vm.handleRegisterError
        )
      }
    },

    resetPassword() {
      const vm = this

      if (vm.resetPasswordEmail) {
        vm.resetPasswordEmail = $.trim(vm.resetPasswordEmail)
      }

      vm.resetPasswordError = undefined

      vm.$auth.resetPassword({email: vm.resetPasswordEmail}).then(
        vm.handleResetPasswordSuccess,
        vm.handleResetPasswordError
      )
    },

    handleLoginSuccess(response) {
      const vm = this
      vm.$auth.setToken(response.data.data.jwt)
      Vue.store.dispatch('loadCurrentUser').then(valid => {
        if (valid) {
          vm.$emit('close-panel')
        } else {
          vm.loginError = vm.$t('errors.invalidUser')
        }
      })
    },

    handleLoginError(response) {
      if (response.status === 422) {
        this.loginError = response.data.errors.session
      }
    },

    handleResetPasswordSuccess() {
      this.localStep = 'resetPasswordFinish'
    },

    handleResetPasswordError(response) {
      if (response.status === 422) {
        this.resetPasswordError = response.data.errors.email
      }
    },

    handleRegisterSuccess(response) {
      const vm = this
      vm.$auth.setToken(response.jwt)
      Vue.store.dispatch('loadCurrentUser').then(() => {
        vm.localStep = 'registerFinish'
      })
    },

    handleRegisterError(response) {
      if (response.status === 422) {
        _.each(response.data.errors, (error, field) => {
          this.registerErrors[field] = error
        })
      }
    },

    resetPasswordLink() {
      this.localStep = 'resetPassword'
    },

    createAccountLink() {
      this.localStep = 'register'
    },

    closePanel() {
      this.$emit('close-panel')
    },

    backToLogin() {
      this.localStep = 'login'
    },

    closePanelHome() {
      if (this.$route.name !== 'home' && !_.includes(this.$route.name, 'municipality')) {
        this.$router.push({name: 'home'})
      }
      this.$emit('close-panel')
    },

    showConditions() {
      const routeData = this.$router.resolve({name: 'privacy-policy'})
      window.open(routeData.href)
    }
  }
})
