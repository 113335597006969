import Vue from 'vue'
import ContactsService from 'js/services/api/contacts.js'
import ContactMixin from 'js/mixins/contact-modal-mixin'

import template from './index.pug'

Vue.component('contact-modal', {
  template: template(),

  mixins: [ContactMixin],

  data() {
    return {
      email: '',
      motive: '',
      observations: '',
      acceptConditions: false,
      formErrors: {},
      acceptConditionsError: ''
    }
  },

  computed: {
    contactSelectOptions() {
      const options = this.selectOptions

      this.selectOptions.forEach(option => {
        option.label = this.$t(option.label)
      })

      return options
    }
  },

  created() {
    if (this.$user) {
      this.email = this.$user.email
    }
  },

  methods: {
    submit() {
      this.resetErrors()

      if (this.acceptConditions) {
        this.sendContact()
      } else {
        this.acceptConditionsError = this.$t('contact.conditionsAcceptError')
      }
    },
    resetErrors() {
      this.formErrors = {}
      this.acceptConditionsError = ''
    },
    sendContact() {
      const data = {
        email: this.email,
        motive: this.motive,
        observations: this.observations
      }

      ContactsService.create(data).then(() => {
        this.sendNotificationSuccess()

        this.close()
      }, response => {
        if (response.status === 422) {
          this.sendNotificationFormErrors()

          this.formErrors = response.data.errors
        } else {
          this.sendNotificationFailure()
        }
      })
    },
    close() {
      this.$emit('close')
    },
    showConditions() {
      const routeData = this.$router.resolve({name: 'privacy-policy'})
      window.open(routeData.href)
    },
    sendNotificationSuccess() {
      this.$notifications.send({
        id: 'contact-success',
        text: this.$t('contact.successMessage'),
        type: 'success',
        close: true
      })
    },
    sendNotificationFormErrors() {
      this.$notifications.send({
        id: 'contact-form-errors',
        text: this.$t('contact.formErrorsMessage'),
        type: 'error',
        close: true
      })
    },
    sendNotificationFailure() {
      this.$notifications.send({
        id: 'contact-failure',
        text: this.$t('contact.failureMessage'),
        type: 'error',
        close: true
      })
    }
  }
})
