import Vue from 'vue'
import {mapGetters, mapActions} from 'vuex'

import AppLogos from 'js/components/layout-footer/_app-logos.js'
import AddressInfo from 'js/components/address-info/index.js'
import PhoneNumber from 'js/components/phone-number/index.js'
import ComplaintsBook from 'js/components/complaints-book/index.js'
import FollowUs from 'js/components/follow-us/index.js'

import template from './index.pug'

Vue.component('layout-footer', {
  template: template(),

  components: {
    AppLogos,
    AddressInfo,
    PhoneNumber,
    ComplaintsBook,
    FollowUs
  },

  computed: {
    ...mapGetters(['currentMunicipality']),

    params() {
      if (!this.currentMunicipality) { return {country: 'rd'} }

      return {
        country: 'rd',
        municipality: this.currentMunicipality.name_normalized
      }
    }
  },

  methods: {
    ...mapActions(['openLanguageModal'])
  }
})
