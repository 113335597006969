import Vue from 'vue'
import template from './index.pug'

// @vue/component
Vue.component('privacy-text', {
  template: template(),

  computed: {
    cookiesPolicyLink() {
      return this.$router.resolve({name: 'cookies-policy'}).href
    }
  }
})
